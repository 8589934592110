@import 'assets/fonts/fonts.css';

html,
body {
  height: 100%;
}

body {
  font-family: Manrope, -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  margin: 0;
}

// Set default font for Angular Material
.mat-typography {
  font-family: Manrope, -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
}
