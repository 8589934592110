@font-face {
    font-family: 'Libre Franklin';
    src: url('../fonts/LibreFranklin-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 900;
    font-style: normal;
}

@font-face {
    font-family: 'Libre Franklin';
    src: url('../fonts/LibreFranklin-Italic-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 900;
    font-style: italic;
}

@font-face {
    font-family: 'Manrope';
    src: url('../fonts/Manrope.woff2') format('woff2');
    font-weight: 300 800;
    font-style: normal;
    font-display: swap;
}
