@use '@angular/material' as mat;
@use './palette-prod' as prod-palettes;
@use './palette-uat' as uat-palettes;

// Include core styles required for all components
@include mat.core();

// Define typography config
$typography-config: mat.define-typography-config();

// Production theme palettes
$prod-primary: mat.define-palette(prod-palettes.$m2-primary);
$prod-accent: mat.define-palette(prod-palettes.$m2-accent, A200, A100, A400);
$prod-warn: mat.define-palette(prod-palettes.$m2-error);

// UAT theme palettes
$uat-primary: mat.define-palette(uat-palettes.$m2-primary);
$uat-accent: mat.define-palette(uat-palettes.$m2-accent, A200, A100, A400);
$uat-warn: mat.define-palette(uat-palettes.$m2-error);

// Production theme configurations
$prod-light-theme: mat.define-light-theme((
  color: (
    primary: $prod-primary,
    accent: $prod-accent,
    warn: $prod-warn,
  ),
  typography: $typography-config,
  density: 0,
));

$prod-dark-theme: mat.define-dark-theme((
  color: (
    primary: $prod-primary,
    accent: $prod-accent,
    warn: $prod-warn,
  ),
  typography: $typography-config,
  density: 0,
));

// UAT theme configurations
$uat-light-theme: mat.define-light-theme((
  color: (
    primary: $uat-primary,
    accent: $uat-accent,
    warn: $uat-warn,
  ),
  typography: $typography-config,
  density: 0,
));

$uat-dark-theme: mat.define-dark-theme((
  color: (
    primary: $uat-primary,
    accent: $uat-accent,
    warn: $uat-warn,
  ),
  typography: $typography-config,
  density: 0,
));

// Include common styles once
@include mat.typography-hierarchy($typography-config);
@include mat.core-theme($prod-light-theme);

// Production theme styles
.prod-theme {
  --mat-primary-text: #{mat.get-color-from-palette($prod-primary, 700)};
  --mat-primary-background: #{mat.get-color-from-palette($prod-primary, 300)};
  --foreground-70: rgba(0, 0, 0, 0.7);
  --foreground-30: rgba(0, 0, 0, 0.3);
  --background-70: rgba(255, 255, 255, 0.7);
  --background-30: rgba(255, 255, 255, 0.3);
  @include mat.all-component-colors($prod-light-theme);

  &.dark-theme {
    --mat-primary-text: #{mat.get-color-from-palette($prod-primary, 300)};
    --mat-primary-background: #{mat.get-color-from-palette($prod-primary, 700)};
    --foreground-70: rgba(255, 255, 255, 0.7);
    --foreground-30: rgba(255, 255, 255, 0.3);
    --background-70: rgba(0, 0, 0, 0.7);
    --background-30: rgba(0, 0, 0, 0.3);
    @include mat.all-component-colors($prod-dark-theme);
  }
}

// UAT theme styles
.uat-theme {
  --mat-primary-text: #{mat.get-color-from-palette($uat-primary, 700)};
  --mat-primary-background: #{mat.get-color-from-palette($uat-primary, 300)};
  --foreground-70: rgba(0, 0, 0, 0.7);
  --foreground-30: rgba(0, 0, 0, 0.3);
  --background-70: rgba(255, 255, 255, 0.7);
  --background-30: rgba(255, 255, 255, 0.3);
  @include mat.all-component-colors($uat-light-theme);

  &.dark-theme {
    --mat-primary-text: #{mat.get-color-from-palette($uat-primary, 300)};
    --mat-primary-background: #{mat.get-color-from-palette($uat-primary, 700)};
    --foreground-70: rgba(255, 255, 255, 0.7);
    --foreground-30: rgba(255, 255, 255, 0.3);
    --background-70: rgba(0, 0, 0, 0.7);
    --background-30: rgba(0, 0, 0, 0.3);
    @include mat.all-component-colors($uat-dark-theme);
  }
}
