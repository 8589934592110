/* These palettes are generated using https://themes.angular-material.dev/ */

$m2-primary: (
    50 : #f3edf9,
    100 : #e0d1f0,
    200 : #ccb3e6,
    300 : #b894db,
    400 : #a87dd4,
    500 : #9966cc,
    600 : #915ec7,
    700 : #8653c0,
    800 : #7c49b9,
    900 : #6b38ad,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ddd0ff,
    A700 : #cab7ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$m2-accent: (
    50 : #fff6f9,
    100 : #ffe7f1,
    200 : #ffd8e7,
    300 : #ffc8dd,
    400 : #ffbcd6,
    500 : #ffb0cf,
    600 : #ffa9ca,
    700 : #ffa0c3,
    800 : #ff97bd,
    900 : #ff87b2,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$m2-error: (
    50 : #fff6f5,
    100 : #ffe9e6,
    200 : #ffdad5,
    300 : #ffcbc4,
    400 : #ffbfb8,
    500 : #ffb4ab,
    600 : #ffada4,
    700 : #ffa49a,
    800 : #ff9c91,
    900 : #ff8c80,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

