/* These palettes are generated using https://themes.angular-material.dev/ */

$m2-primary: (
    50 : #f6ece0,
    100 : #e9cfb3,
    200 : #dab080,
    300 : #cb904d,
    400 : #c07826,
    500 : #b56000,
    600 : #ae5800,
    700 : #a54e00,
    800 : #9d4400,
    900 : #8d3300,
    A100 : #ffefef,
    A200 : #ffbebc,
    A400 : #ff8d89,
    A700 : #ff756f,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$m2-accent: (
    50 : #f8f9eb,
    100 : #edf1cc,
    200 : #e1e7ab,
    300 : #d4dd89,
    400 : #cbd66f,
    500 : #c2cf56,
    600 : #bcca4f,
    700 : #b4c345,
    800 : #acbd3c,
    900 : #9fb22b,
    A100 : #ffffff,
    A200 : #ffffe2,
    A400 : #ffffaf,
    A700 : #ffff96,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$m2-error: (
    50 : #fff6f5,
    100 : #ffe9e6,
    200 : #ffdad5,
    300 : #ffcbc4,
    400 : #ffbfb8,
    500 : #ffb4ab,
    600 : #ffada4,
    700 : #ffa49a,
    800 : #ff9c91,
    900 : #ff8c80,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

